import { css } from '@emotion/react';
import {
  fontSize,
  lineHeight,
  spacing,
} from '@prototyp/gatsby-plugin-gumball/mixins';
import { font, opacity } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  outline: 0;
  background-color: transparent;
  transition: background-color 0.3s ease, border 0.3s ease;
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  border-radius: var(--border-radius-regular);

  &:disabled {
    ${opacity.o50};
    pointer-events: none;
  }
`;

export const text = css`
  display: inline-block;
  flex-grow: 1;
`;

export const icon = css`
  margin-right: ${spacing(12)};
`;

export const iconSmall = css`
  margin-right: ${spacing(8)};
`;

export const iconRight = css`
  margin-left: ${spacing(12)};
`;

export const iconRightSmall = css`
  margin-left: ${spacing(8)};
`;

export const micro = css`
  padding: ${spacing(4)} ${spacing(16)};
  font-size: ${fontSize('micro')};
  line-height: ${lineHeight('micro')};
`;

export const tiny = css`
  padding: ${spacing(6)} ${spacing(16)};
  font-size: ${fontSize('xsmall')};
  line-height: ${lineHeight('xsmall')};
`;

export const small = css`
  padding: ${spacing(10)} ${spacing(16)};
  font-size: ${fontSize('xsmall')};
  line-height: ${lineHeight('xsmall')};
`;

export const medium = css`
  padding: ${spacing(12)} ${spacing(20)};
  font-size: ${fontSize('small')};
  line-height: ${lineHeight('small')};
`;

export const large = css`
  padding: ${spacing(16)} ${spacing(24)};
  font-size: ${fontSize('base')};
  line-height: ${lineHeight('base')};
`;

export const smallExtended = css`
  padding: ${spacing(10)} ${spacing(36)};
  font-size: ${fontSize('xsmall')};
  line-height: ${lineHeight('xsmall')};
`;
export const mediumExtended = css`
  padding: ${spacing(12)} ${spacing(44)};
  font-size: ${fontSize('small')};
  line-height: ${lineHeight('small')};
`;
export const largeExtended = css`
  padding: ${spacing(16)} ${spacing(52)};
  font-size: ${fontSize('base')};
  line-height: ${lineHeight('base')};
`;

export const primary = css`
  ${mediumExtended};

  background-color: hsl(var(--color-text-1));
  color: hsl(var(--color-white-11));

  &:hover,
  &:active {
    background-color: hsl(var(--color-text-2));
  }
`;

export const hazard = css`
  ${mediumExtended};

  background-color: hsl(var(--color-warning-6));
  color: hsl(var(--color-white-11));

  &:hover,
  &:active {
    background-color: hsl(var(--color-warning-4));
  }
`;

export const secondary = css`
  ${mediumExtended};

  background-color: hsl(var(--color-robin-2));
  color: hsl(var(--color-text-1));

  &:hover,
  &:active {
    background-color: hsl(var(--color-robin-4));
  }
`;

export const ghost = css`
  ${mediumExtended};

  color: hsl(var(--color-text-1));
`;

export const neutral = css`
  ${mediumExtended};

  background-color: hsl(var(--color-grayscale-2));
  color: hsl(var(--color-text-1));

  &:hover,
  &:active {
    background-color: hsl(var(--color-grayscale-3));
  }
`;

export const outline = css`
  ${mediumExtended};

  border: 1px solid hsl(var(--color-borderLight-8));
  color: hsl(var(--color-text-1));

  &:hover,
  &:active {
    border: 1px solid hsl(var(--color-borderBlack-2));
  }
`;

export const disabled = css`
  ${opacity.o50};
  pointer-events: none;
`;

export const svgButton = css`
  path {
    fill: hsl(var(--color-textLight-7));
  }

  &:hover,
  &:focus {
    path {
      fill: hsl(var(--color-text-1));
    }
  }
`;

export const svgButtonDark = css`
  path {
    fill: hsl(var(--color-textLight-7));
  }

  &:hover,
  &:focus {
    path {
      fill: hsl(var(--color-grayscale-1));
    }
  }
`;

export const statusButton = (data: {
  color?: string;
  backgroundColor: string;
  activeBackgroundColor: string;
}) => css`
  ${micro}
  ${font.weight.regular}
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 6px;
  background-color: ${data.backgroundColor};
  color: ${data.color};

  &:hover,
  &:active,
  &:focus {
    background-color: ${data.activeBackgroundColor};
  }

  svg {
    path {
      fill: ${data.color};
    }
  }
`;
